import { HeaderLK } from "../Components/HeaderLK";
import { MainContent } from "../Components/MainContent";
import left from '../icons/more-group-left.png'
import right from '../icons/more-group-right.png'

export function ScoringLK() {
  return (
    <div className="mainPage">
      <HeaderLK></HeaderLK><div className="backGroundLeft"></div>
      <div className="backGroundRight"></div>
      <div className="mainSpan">
      
        <div>Антология</div>
      </div>
      <MainContent></MainContent>
    </div>
  );
}
