import { TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function EnterResult() {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [date, setDate] = useState("");

  function refresh() {
    window.location.reload();
  }

  function goResult(e) {
    e.preventDefault();
    if (code.length > 0) {
      const formdata = new FormData();
      formdata.append("userid", code);
      formdata.append("date", date);

      axios
        .post("https://bot.rgsu.net/we/stat.py", formdata)
        .then((response) => {
          try {
            const parser = new DOMParser();
            const xml = parser.parseFromString(
              response.data,
              "application/xml"
            );

            let list = xml.querySelectorAll("block");

            let graphList = [];

            for (let i = 0; i < list.length; i++) {
              graphList.push(list[i].textContent);
            }

            localStorage.setItem("graphList", JSON.stringify(graphList));

            localStorage.setItem(
              "socialStatus",
              xml.querySelector("full").textContent
            );

            localStorage.setItem(
              "statisticsName",
              xml.querySelector("name").textContent
            );

            localStorage.setItem(
              "statisticsAge",
              xml.querySelector("age").textContent
            );

            navigate("/statistics");
            document.body.style.overflow = "";
          } catch {
            alert("Данные введены неверно, либо результат ещё не подсчитан");
            return;
          }
        });
    } else {
      return;
    }
  }

  function changeCode(e) {
    setCode(e.target.value);
  }

  function changeDate(e) {
    setDate(e.target.value);
  }

  return (
    <div className="pop_up ">
      <div className="pop_window result">
        <div className="closeBox">
          <button onClick={refresh}>&#215;</button>
        </div>
        <form className="EnterComponents">
          <div className="enterTitle">
            Введите личный код и дату прохождения
          </div>
          <TextField
            value={code}
            onChange={changeCode}
            id="login"
            label="Код"
            variant="standard"
            className="textInputEnter"
            required
          />

          <TextField
            value={date}
            onChange={changeDate}
            label="Дата прохождения"
            type="date"
            className="textInputEnter"
            InputLabelProps={{ shrink: true, required: true }}
            required
          />

          <button className="btn_Enter" onClick={goResult}>
            Продолжить
          </button>
        </form>
      </div>
    </div>
  );
}
