import React from 'react';
import classes from './Textarea.module.css'

const Textarea = (props) => {
    return (
        <textarea {...props} className={classes.Textarea}>

        </textarea>
    );
};

export default Textarea;
