import {
  InputLabel,
  Select,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { Header } from "../Components/Header";
import Footer from "../Components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import ReactLoading from "react-loading";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";
import firstFile from "../docs/Пользовательское соглашение.pdf";
import secondFile from "../docs/Согласие на обработку персональных данных.pdf";
import thirdFile from "../docs/Соглашение о защите от копирования и распространения информации.pdf";

const regions = [
  "Республика Адыгея",
  "Республика Башкортостан",
  "Республика Бурятия",
  "Республика Алтай (Горный Алтай)",
  "Республика Дагестан",
  "Республика Ингушетия",
  "Кабардино-Балкарская Республика",
  "Республика Калмыкия",
  "Республика Карачаево-Черкессия",
  "Республика Карелия",
  "Республика Коми",
  "Республика Марий Эл",
  "Республика Мордовия",
  "Республика Саха (Якутия)",
  "Республика Северная Осетия — Алания",
  "Республика Татарстан",
  "Республика Тыва",
  "Удмуртская Республика",
  "Республика Хакасия",
  "Чувашская Республика",
  "Алтайский край",
  "Краснодарский край",
  "Красноярский край",
  "Приморский край",
  "Ставропольский край",
  "Хабаровский край",
  "Амурская область",
  "Архангельская область",
  "Астраханская область",
  "Белгородская область",
  "Брянская область",
  "Владимирская область",
  "Волгоградская область",
  "Вологодская область",
  "Воронежская область",
  "Ивановская область",
  "Иркутская область",
  "Калининградская область",
  "Калужская область",
  "Камчатский край",
  "Кемеровская область",
  "Кировская область",
  "Костромская область",
  "Курганская область",
  "Курская область",
  "Ленинградская область",
  "Липецкая область",
  "Магаданская область",
  "Московская область",
  "Мурманская область",
  "Нижегородская область",
  "Новгородская область",
  "Новосибирская область",
  "Омская область",
  "Оренбургская область",
  "Орловская область",
  "Пензенская область",
  "Пермский край",
  "Псковская область",
  "Ростовская область",
  "Рязанская область",
  "Самарская область",
  "Саратовская область",
  "Сахалинская область",
  "Свердловская область",
  "Смоленская область",
  "Тамбовская область",
  "Тверская область",
  "Томская область",
  "Тульская область",
  "Тюменская область",
  "Ульяновская область",
  "Челябинская область",
  "Забайкальский край",
  "Ярославская область",
  "г. Москва",
  "г. Санкт-Петербург",
  "Еврейская автономная область",
  "Республика Крым",
  "Ненецкий автономный округ",
  "Ханты-Мансийский автономный округ — Югра",
  "Чукотский автономный округ",
  "Ямало-Ненецкий автономный округ",
  "г. Севастополь",
  "территории, находящиеся за пределами РФ (режимные объекты)",
  "Чеченская республика",
  "Луганск",
  "Донецк",
  "Алтайский край (Барнаул)",
];
// const regions = [
//   "Москва",
//   "Луганск",
//   "Донецк",
//   "Ставропольский край",
//   "Якутия",
//   "Крым",
//   "Карачаево-Черкесия",
//   "Барнаул",
//   "Санкт-Петербург",
// ];

function yearDiff(dt1, dt2) {
  var diffYear = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
  diffYear /= 60 * 60 * 24;
  return Math.abs(Math.round(diffYear / 365.25));
}

export function Registration() {
  const navigate = useNavigate();
  const [loading, setLoad] = useState(false);
  const [check, setCheck] = useState(false);
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [family, setFamily] = useState("");
  const [place, setPlace] = useState(null);
  const [dependency, setDependecy] = useState("");
  const [birthYear, setBirthYear] = useState();
  const [materialValue, setMaterialValue] = useState("");
  // const [language, setLanguage] = useState("");
  // const [nameVer, setNameVer] = useState(false);
  // const [email, setEmail] = useState("");
  // const [emailPass, setEmailPass] = useState(false);

  // useEffect(() => {
  //   if (document.getElementById("name").value) {
  //     setNameVer(true);
  //   } else {
  //     setNameVer(false);
  //   }
  // });

  // useEffect(() => {
  //   setLanguages(["русский", ...languages]);
  // }, []);

  // const mailTest = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;

  // useEffect(() => {
  //   if (email.length > 0 && mailTest.test(email)) {
  //     setEmailPass(true);
  //   }
  //   if (email.length == 0) {
  //     setEmailPass(true);
  //   }
  //   if (email.length > 0 && !mailTest.test(email)) {
  //     setEmailPass(false);
  //   }
  // });

  async function startTest(e) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    let dateResult = yyyy - birthYear;

    if (
      (14 > dateResult || dateResult > 80) &&
      document.getElementById("date").value.length > 0
    ) {
      e.preventDefault();
      alert("Введите возраст от 14 до 80");
    }

    if (
      14 <= dateResult &&
      dateResult <= 80 &&
      // check &&
      education &&
      family &&
      materialValue &&
      dependency &&
      // nameVer &&
      // language.length != 0 &&
      place.length != 0
      // emailPass
    ) {
      e.preventDefault();
      // localStorage.setItem("fullName", document.getElementById("name").value);
      // localStorage.setItem("email", document.getElementById("email").value);
      localStorage.setItem("gender", gender);
      localStorage.setItem("education", education);
      localStorage.setItem("family", family);
      localStorage.setItem("dependency", dependency);
      localStorage.setItem("materialValue", materialValue);
      localStorage.setItem("birthDate", document.getElementById("date").value);
      // localStorage.setItem("language", language);
      localStorage.setItem("place", place);
      localStorage.setItem("next", "start");

      const formdata = new FormData();
      // formdata.append("fullName", localStorage.getItem("fullName"));
      // formdata.append("email", localStorage.getItem("email"));
      formdata.append("gender", localStorage.getItem("gender"));
      formdata.append("birthDate", localStorage.getItem("birthDate"));
      // formdata.append("language", localStorage.getItem("language"));
      formdata.append("place", localStorage.getItem("place"));
      formdata.append("next", localStorage.getItem("next"));
      formdata.append("education", localStorage.getItem("education"));
      formdata.append("family", localStorage.getItem("family"));
      formdata.append("dependency", localStorage.getItem("dependency"));
      formdata.append("materialValue", localStorage.getItem("materialValue"));

      setLoad(true);

      await axios
        // .get("https://bot.rgsu.net/we/main.py", {
        //   params: {
        //     fullName: localStorage.getItem("fullName"),
        //     email: localStorage.getItem("email"),
        //     gender: localStorage.getItem("gender"),
        //     birthDate: localStorage.getItem("birthDate"),
        //     language: localStorage.getItem("language"),
        //     place: localStorage.getItem("place"),
        //     next: localStorage.getItem("next"),
        //   },
        .post("https://xyz.rgsu.net/new.py", formdata)
        .then((response) => {
          setLoad(false);
          const parser = new DOMParser();
          const xml = parser.parseFromString(response.data, "application/xml");

          let dataItems = xml.querySelectorAll("answer");
          let dataIdItems = xml.querySelectorAll("answerid");

          let dataQuizList = [];

          for (let i = 0; i < dataItems.length; i++) {
            let quizData = {
              quizname: dataItems[i].textContent,
              quizid: dataIdItems[i].textContent,
            };
            dataQuizList.push(quizData);
          }
          // setQuizList(dataQuizList)
          localStorage.setItem("answer", JSON.stringify(dataQuizList));
          localStorage.setItem("pageNumber", 0);

          localStorage.setItem(
            "questionNumber",
            xml.querySelector("step").textContent
          );

          localStorage.setItem(
            "sessionid",
            xml.querySelector("sessionid").textContent
          );

          localStorage.setItem("question", xml.querySelector("q").textContent);
          localStorage.setItem("type", xml.querySelector("type").textContent);
        });

      window.scrollTo(0, 0);
      navigate("/test");
    }
  }

  const handleYear = (event) => {
    const result = event.target.value.replace(/[^0-9]+/g, "");
    setBirthYear(result);
  };

  const handlePress = (event) => {
    return (event.which > 47 && event.which < 58) || event.which == 13;
  };

  const handleAutocompleteChange = (event, value) => {
    setPlace(value);
  };

  return (
    <div>
      <Header></Header>{" "}
      <div className="registrationContent">
        <div className="mainSpan">
          <div>Анкета респондента</div>
        </div>
        {/* <div className="warningRegistration">
          Поля отмеченные символом * обязательны для заполнения
        </div> */}
        <form className="registrationForm">
          {/* <TextField
            id="name"
            label="Введите ФИО или псевдоним"
            variant="standard"
            className="textInput"
            name="fullName"
            required
          /> */}
          {/* <TextField
            id="email"
            label="E-mail"
            variant="standard"
            className="textInput"
            type="email"
            name="email"
            value={email}
            onChange={changeEmail}
          /> */}
          <TextField
            id="date"
            label="Год рождения"
            InputLabelProps={{ shrink: true, required: true }}
            className="textInput"
            type="text"
            name="birthDate"
            inputProps={{ maxLength: 4 }}
            value={birthYear}
            onChange={handleYear}
            onKeyDown={handlePress}
            required
          />{" "}
          <FormControl>
            <InputLabel id="select-label" required>
              Выберите пол
            </InputLabel>
            <Select
              labelId="select-label"
              id="gender-select"
              label="Выберите пол"
              name="gender"
              native
              defaultValue=""
              onChange={(e) => {
                setGender(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              <option value="Мужчина">Мужcкой</option>
              <option value="Женщина">Женский</option>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-label" required>
              Уровень Вашего образования
            </InputLabel>
            <Select
              labelId="select-label"
              id="education-select"
              label="Выберите уровень Вашего образования"
              name="education"
              native
              defaultValue=""
              onChange={(e) => {
                setEducation(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              <option value="Основное общее">Основное общее</option>
              <option value="Среднее общее">Среднее общее</option>
              <option value="СПО">СПО</option>
              <option value="Бакалавриат">Бакалавриат</option>
              <option value="Специалитет">Специалитет</option>
              <option value="Магистратура">Магистратура</option>
              <option value="Аспирантура">Аспирантура</option>
            </Select>
          </FormControl>
          <FormControl>
            <Autocomplete
              labelId="select-label"
              id="place-select"
              name="place"
              freeSolo
              disableClearable
              defaultValue=""
              onChange={handleAutocompleteChange}
              options={[...regions.sort((a, b) => a.localeCompare(b)), "Иное"]}
              renderInput={(params) => (
                <TextField {...params} label="Регион проживания" required />
              )}
              required
            />
          </FormControl>
          {/* const regions = [
  "Москва",
  "Луганск",
  "Донецк",
  "Ставропольский край",
  "Якутия",
  "Крым",
  "Карачаево-Черкесия",
  "Барнаул",
  "Санкт-Петербург",
]; */}
          <FormControl>
            <InputLabel id="select-label" required>
              Семейный статус
            </InputLabel>
            <Select
              labelId="select-label"
              id="family-select"
              label="Выберите уровень Вашего образования"
              name="family"
              native
              defaultValue=""
              onChange={(e) => {
                setFamily(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              <option value="Не женат/не замужем">Не женат/не замужем</option>
              <option value="В официальном браке">В официальном браке</option>
              <option value="В гражданском браке">В гражданском браке</option>
              <option value="В разводе">В разводе</option>
              <option value="Вдовец/вдова">Вдовец/вдова</option>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-label" required>
              Наличие лиц на Вашем иждивении
            </InputLabel>
            <Select
              labelId="select-label"
              id="dependency-select"
              label="Выберите уровень Вашего образования"
              name="dependency"
              native
              defaultValue=""
              onChange={(e) => {
                setDependecy(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              <option value="Несовершеннолетние дети">
                Несовершеннолетние дети
              </option>
              <option value="Жена/Муж">Жена/Муж</option>
              <option value="Родители">Родители</option>
              <option value="Домашние животные">Домашние животные</option>
              <option value="Другое">Другое</option>
              <option value="Нет">Нет</option>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-label" required>
              Экономическая состоятельность
            </InputLabel>
            <Select
              labelId="select-label"
              id="materialValue-select"
              label="Экономическая состоятельность"
              name="materialValue"
              native
              defaultValue=""
              onChange={(e) => {
                setMaterialValue(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              <option value="Нахожусь на иждивении">
                Нахожусь на иждивении
              </option>
              <option value="Финансово независим от других">
                Финансово независим от других
              </option>
              <option value="Имею собственность<">Имею собственность</option>
            </Select>
          </FormControl>
          {/* <FormControl>
            <Autocomplete
              labelId="select-label"
              id="language-select"
              name="language"
              freeSolo
              disableClearable
              defaultValue=""
              onChange={(e) => {
                setPlace(e.target.value);
              }}
              options={languages}
              renderInput={(params) => (
                <TextField {...params} label=" Родной язык" required />
              )}
            />
          </FormControl> */}
          {/* <a href={firstFile}>Пользовательское соглашение</a>
          <a href={secondFile}>Согласие на обработку персональных данных</a>
          <a href={thirdFile}>
            Соглашение о защите от копирования и распространения информации
          </a> */}
          <div className="loadingBox">
            {loading ? (
              <ReactLoading
                type="spinningBubbles"
                color="black"
                height={"50px"}
                width={"50px"}
              />
            ) : (
              <button className="btn" onClick={startTest}>
                Перейти к опросу
              </button>
            )}
          </div>
        </form>
      </div>
      <Footer></Footer>
    </div>
  );
}
