import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import { MainContent } from "../Components/MainContent";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";

export function MainPage() {
 

  return (
    <div className="mainPage">
      <Header></Header>
      <div className="mainSpan">
        <div>Антология</div>
      </div>{" "}
    
     
      <MainContent></MainContent>
      <Footer></Footer>
    </div>
  );
}
