import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { useState } from "react";

export function MainContent() {
  const navigate = useNavigate();
  const [loading, setLoad] = useState(false);

  function goRegistration() {
    navigate("/registration");
    localStorage.clear();
  }

  async function startTest() {
    const formdata = new FormData();
    formdata.append("gender", "0");
    formdata.append("birthDate", "0");
    formdata.append("place", "0");
    formdata.append("next", "0");
    formdata.append("education", "0");
    formdata.append("family", "0");
    formdata.append("dependency", "0");
    formdata.append("materialValue", "0");

    setLoad(true);

    await axios
      .post("https://xyz.rgsu.net/new.py", formdata)
      .then((response) => {
        setLoad(false);
        const parser = new DOMParser();
        const xml = parser.parseFromString(response.data, "application/xml");

        let dataItems = xml.querySelectorAll("answer");
        let dataIdItems = xml.querySelectorAll("answerid");

        let dataQuizList = [];

        for (let i = 0; i < dataItems.length; i++) {
          let quizData = {
            quizname: dataItems[i].textContent,
            quizid: dataIdItems[i].textContent,
          };
          dataQuizList.push(quizData);
        }
        localStorage.setItem("answer", JSON.stringify(dataQuizList));
        localStorage.setItem("pageNumber", 0);

        localStorage.setItem(
          "questionNumber",
          xml.querySelector("step").textContent
        );

        localStorage.setItem(
          "sessionid",
          xml.querySelector("sessionid").textContent
        );

        localStorage.setItem("question", xml.querySelector("q").textContent);
        localStorage.setItem("type", xml.querySelector("type").textContent);
      });
    navigate("/test");
  }

  return (
    <div className="mainContent">
      <div className="mainPageSpan">
        Для начала прохождения опроса нажмите кнопку "Начать" и заполните анкету
        респондента
      </div>
      {loading ? (
        <ReactLoading
          type="spinningBubbles"
          color="black"
          height={"50px"}
          width={"50px"}
        />
      ) : (
        <button className="btn" onClick={goRegistration}>
          Начать
        </button>
      )}
    </div>
  );
}
