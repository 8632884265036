import React from "react";
import { useNavigate } from "react-router-dom";
import firstFile from "../docs/Пользовательское соглашение.pdf";
import secondFile from "../docs/Согласие на обработку персональных данных.pdf";
import thirdFile from "../docs/Соглашение о защите от копирования и распространения информации.pdf";

function Footer() {
  const navigate = useNavigate();

  function goMain() {
    navigate("/");
  }

  return (
    <div className="footer">
      <div className="logoBox">
        <div className="logo logoFooter" onClick={goMain}>
          Антология
        </div>
      </div>
      {/* <div className="footerContent">
        {" "}
        <a className="footerButton" href={firstFile}>
          Пользовательское соглашение
        </a>
        <a className="footerButton" href={secondFile}>
          Согласие на обработку персональных данных
        </a>
        <a className="footerButton" href={thirdFile}>
          Соглашение о защите от копирования и распространения информации
        </a>
      </div> */}
    </div>
  );
}

export default Footer;
