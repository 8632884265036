import { Header } from "../Components/Header";
import { HeaderLK } from "../Components/HeaderLK";
import left from '../icons/more-group-left.png'
import right from '../icons/more-group-right.png'

export function PersonalInfo() {
  return (
    <div>
      <HeaderLK></HeaderLK>
      <div className="personalSpan">

        <span>Личные данные</span>
      </div>
    </div>
  );
}
