import { useEffect, useState } from "react";
import { Enter } from "./Enter";
import { useNavigate } from "react-router-dom";
import { EnterResult } from "./EnterResult";
import axios from "axios";

export function Header() {
  const navigate = useNavigate();
  const [pop, setPop] = useState(false);
  const [side, setSide] = useState(false);
  const [result, setResult] = useState(false);

  function goMain() {
    navigate("/");
  }

  const showPop = () => {
    window.scrollTo(0, 0);

    setPop(true);
    if (side) {
      setPop(false);
    }

    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  };

  const showResult = () => {
    window.scrollTo(0, 0);

    setResult(true);
    if (side) {
      setResult(false);
    }

    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

     };

  function goRegistration() {
    navigate("/registration");
    localStorage.clear();
  }

  return (
    <div className="header-color">
      <div className="header">
        <div className="logoBox">
          <div className="logo" onClick={goMain}>
            Антология
          </div>
        </div>

        <div className="headerButtons">
          {/* <button onClick={showResult} className="goResult">Мой результат</button> */}
          {/* <button onClick={showPop}>Вход</button> */}
          {/* <button onClick={goRegistration}>Регистрация</button> */}
        </div>
        {pop && <Enter />}
        {result && <EnterResult></EnterResult>}
      </div>
    </div>
  );
}
