import { useEffect, useState } from "react";
import { PopUp } from "./PopUp";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  TextField,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import axios from "axios";
import ReactLoading from "react-loading";
import Slider from "@mui/material/Slider";
import Textarea from "./Textarea/Textarea";

export function TestContent() {
  const [text, setText] = useState(localStorage.getItem("question"));
  const [inputType, setInputType] = useState("");
  const [input, setInput] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [variantsList, setVariants] = useState(
    JSON.parse(localStorage.getItem("answers"))
  );
  const [pop, setPop] = useState(false);
  const [loading, setLoad] = useState(false);
  const [final, setFinal] = useState(
    JSON.parse(localStorage.getItem("final")) || false
  );
  const [inputV, setInputV] = useState([]);
  const [answer, setAnswer] = useState();
  const [testNumber, setNumber] = useState(
    Number(localStorage.getItem("questionNumber"))
  );
  const [answerId, setId] = useState(
    JSON.parse(localStorage.getItem("answerid"))
  );
  const [last, setLast] = useState(0);
  const [tests, setTests] = useState({
    number: localStorage.getItem("questionNumber"),
    text: localStorage.getItem("question"),
    type: localStorage.getItem("type"),
    variants: JSON.parse(localStorage.getItem("answer")),
    id: JSON.parse(localStorage.getItem("answerid")),
  });
  const [checkV, setCheckV] = useState("");
  const [radioV, setRadioV] = useState([]);
  const [quizList, setQuizList] = useState([]);

  window.onbeforeunload = function () {
    return false;
  };

  useEffect(() => {
    localStorage.setItem("final", final);
  }, [final]);

  useEffect(() => {
    setText(localStorage.getItem("question"));

    let dataItems = JSON.parse(localStorage.getItem("answer"));

    let dataQuizList = [];

    for (let i = 0; i < dataItems.length; i++) {
      let quizData = {
        quizname: dataItems[i].quizname,
        quizid: dataItems[i].quizid,
      };
      dataQuizList.push(quizData);
    }
    setQuizList(dataQuizList);
  }, [Number(localStorage.getItem("questionNumber"))]);

  async function nextTest(e) {
    e.preventDefault();

    window.scrollTo(0, 0);

    const formdata = new FormData();
    formdata.append("answer", answer);
    formdata.append("sessionid", localStorage.getItem("sessionid"));
    formdata.append("step", Number(localStorage.getItem("questionNumber")) + 1);

    let empty = [];

    for (let i in inputV) {
      if (!inputV[i] || /[^\s]/gim.test(inputV[i]) === false) {
        empty.push(inputV[i]);
      }
    }

    if (JSON.parse(localStorage.getItem("final")) === false) {
      if (empty.length > 0 || answer.length != quizList.length) {
        alert("Пожалуйста, заполните все поля");
      } else {
        setLoad(true);
        axios.post("https://xyz.rgsu.net/new.py", formdata).then((response) => {
          setLoad(false);
          const parser = new DOMParser();
          var xml = parser.parseFromString(response.data, "application/xml");
          let dataItems = xml.querySelectorAll("answer");
          let dataIdItems = xml.querySelectorAll("answerid");

          let dataQuizList = [];

          for (let i = 0; i < dataItems.length; i++) {
            let quizData = {
              quizname: dataItems[i].textContent,
              quizid: dataIdItems[i].textContent,
            };
            dataQuizList.push(quizData);
          }

          setQuizList(dataQuizList);

          localStorage.setItem("answer", JSON.stringify(dataQuizList));
          localStorage.setItem("question", xml.querySelector("q").textContent);
          localStorage.setItem(
            "questionNumber",
            xml.querySelector("step").textContent
          );
          localStorage.setItem("type", xml.querySelector("type").textContent);

          setLast(xml.querySelector("islast").textContent);
        });

        setInputV([]);
      }
    } else if (selectedValues.length < quizList.length) {
      alert("Пожалуйста, заполните все поля");
    } else {
      setLoad(true);

      const formdata = new FormData();
      formdata.append("answer", selectedValues);
      formdata.append("sessionid", localStorage.getItem("sessionid"));
      formdata.append("step", Number(localStorage.getItem("questionNumber")));
      formdata.append("isLast", "1");

      await axios.post("https://xyz.rgsu.net/new.py", formdata);
      window.scrollTo(0, 0);
      setPop(true);
      setLoad(false);
      localStorage.setItem("final", false);
    }
  }

  useEffect(() => {
    if (last == 1) {
      setFinal(true);
    }
    if (testNumber == tests.number) {
      if (tests.type == "text") {
        setAnswer(inputV);
        setInput(true);
        setInputType("text");
      }
      if (tests.type == "slide") {
        setAnswer(inputV);
        setInput(true);
        setInputType("slide");
      }
      if (tests.type == "radio") {
        setAnswer(selectedValues);
        setInput(true);
        setInputType("radio");
      }
    }
  });

  const handleText = (event, index) => {
    const newValues = [...inputV];
    newValues[index] = event.target.value;
    setInputV(newValues);
  };

  const exceptionsDictionary = [
    "Экстремальная",
    "Экстренная",
    "Чрезвычайная",
    "Нейтральная",
  ];

  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedValues((prevValues) => [...prevValues, event.target.value]);
    } else {
      setSelectedValues((prevValues) =>
        prevValues.filter((value) => value !== event.target.value)
      );
    }
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://code.getmdl.io/1.3.0/material.indigo-pink.min.css"
      />

      <div className="mainSpan">
        <div>Вопрос {Number(localStorage.getItem("questionNumber"))}</div>
      </div>
      <div className="mainContent">
        <span className="headTest">{text}</span>
        <div className="testContent">
          {localStorage.getItem("type") == "text" && (
            <>
              {quizList?.map((item, i) => {
                return (
                  <>
                    <div className="quizListText" key={item.quizid}>
                      {item.quizname}
                    </div>
                    <TextField
                      key={i}
                      value={inputV[i] || ""}
                      onChange={(event) => {
                        handleText(event, i);
                      }}
                      id="text-input"
                      label="Введите"
                      variant="standard"
                      className="textInput"
                      type="text"
                      required
                    />
                  </>
                );
              })}
            </>
          )}
          {localStorage.getItem("type") == "textarea" && (
            <>
              {quizList?.map((item, i) => {
                return (
                  <>
                    <div className="quizListText" key={item.quizid}>
                      {item.quizname}
                    </div>
                    <div className="testContent" style={{ margin: "0" }}>
                      <Textarea
                        key={i}
                        value={inputV[i] || ""}
                        onChange={(event) => handleText(event, i)}
                        id="text-input"
                        label="Введите"
                        placeholder="Введите..."
                        variant="standard"
                        className="textInput"
                        type="text"
                        required
                      />
                    </div>
                  </>
                );
              })}
            </>
          )}
          {localStorage.getItem("type") == "slide" && (
            <>
              {quizList?.map((item, i) => {
                return (
                  <>
                    <div className="quizListText" key={item.quizid}>
                      {item.quizname}
                    </div>
                    <Slider
                      key={i}
                      value={inputV[i] || "0"}
                      onChange={(event) => handleText(event, i)}
                      defaultValue={5}
                      min={0}
                      max={10}
                      step={1}
                      marks={true}
                      orientation="horizontal"
                      valueLabelDisplay="auto"

                      // onChange={handleSliderChange(i)}
                      // onChange={(event, newValue) => {
                      //   console.log(newValue);
                      // }
                      // }
                    />
                  </>
                );
              })}
            </>
          )}
          {localStorage.getItem("type") == "radio" && (
            <>
              {quizList?.map((item, i) => {
                return (
                  <>
                    <div
                      className="quizListText quizListTextAlot"
                      key={item.quizid}
                    >
                      {item.quizname}
                    </div>
                    <div className="checkBig">
                      {/*<FormControl>*/}
                      {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        style={{ display: "flex" }}
                      >
                        {exceptionsDictionary?.map((item, i) => {
                          return (
                            <FormControlLabel
                              key={item.label}
                              className="checkLabel"
                              control={
                                <Radio
                                  className="checkInput"
                                  value={item}
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={item}
                              value={i}
                            />
                          );
                        })}
                      </RadioGroup>
                      {/*</FormControl>*/}
                    </div>
                    <div className="radioGroupLine" />
                  </>
                );
              })}
            </>
          )}
        </div>
        <div className="nextBox">
          {loading ? (
            <ReactLoading
              type="spinningBubbles"
              color="black"
              height={"50px"}
              width={"50px"}
            />
          ) : final ? (
            <button onClick={nextTest}>Закончить</button>
          ) : (
            <button className="btn" onClick={nextTest}>
              Далее
            </button>
          )}
        </div>
      </div>
      {pop && <PopUp />}
    </div>
  );
}
