import { useNavigate } from "react-router-dom";
import { HeaderLK } from "../Components/HeaderLK";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Header } from "../Components/Header";
import { Pie, Radar } from "react-chartjs-2";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";
import Footer from "../Components/Footer";
import "chart.js/auto";
import { useResize } from "../Hooks/useResize";

export function Statistics() {
  const navigate = useNavigate();
  const [loading, setLoad] = useState();

  const emptyChart = ["", "", "", "", "", ""];

  const labelInfo = [
    "Состав домохозяйств",
    "Уровень жизни",
    "Работа и образование",
    "Отягощения",
    "Социальная активность",
    "Здоровье и и физическая активность",
  ];

  const chartBackground = [
    "#8ebfff",
    "#00A6B4",
    "#50c6ca",
    "#4d7fbe",
    "#3b71b86e",
    "#004094",
    "#390094",
  ];

  const chartHover = [
    "#3b71b88f",
    "#00a5b47a",
    "#50c6ca8c",
    "#4d80be80",
    "#3b71b82d",
    "#00409475",
    "3900946b",
    "b002db6b",
  ];

  const width = useResize();

  const pieChartData = {
    labels: labelInfo,
    datasets: [
      {
        label: "Баллы",
        backgroundColor: chartBackground,
        hoverBackgroundColor: chartHover,
        data: JSON.parse(localStorage.getItem("graphList")),
      },
    ],
  };

  const radarChartData = {
    labels: width.width > 500 ? labelInfo : emptyChart,
    datasets: [
      {
        label: "Баллы",
        backgroundColor: chartBackground,
        hoverBackgroundColor: chartHover,
        data: JSON.parse(localStorage.getItem("graphList")),
      },
    ],
  };

  useEffect(() => {
    if (localStorage.getItem("graphList") == null) {
      navigate("/");
    }
  }, []);

  async function startTest(e) {
    e.preventDefault();

    // setLoad(true);
    // await axios
    //   .get("https://bot.rgsu.net/we/we.py", {
    //     params: {
    //       fullName: localStorage.getItem("fullName"),
    //       email: localStorage.getItem("email"),
    //       gender: localStorage.getItem("gender"),
    //       birthDate: localStorage.getItem("birthDate"),
    //       language: localStorage.getItem("language"),
    //       place: localStorage.getItem("place"),
    //       next: "start",
    //     },
    //   })
    //   .then((response) => {
    //     setLoad(false);
    //     const parser = new DOMParser();
    //     const xml = parser.parseFromString(response.data, "application/xml");

    //     localStorage.setItem("pageNumber", 0);

    //     localStorage.setItem(
    //       "questionNumber",
    //       xml.querySelector("step").textContent
    //     );
    //     localStorage.setItem(
    //       "question",
    //       xml.querySelector("query").textContent
    //     );
    //     let answers = xml.querySelectorAll("ans");
    //     let answersList = [];

    //     for (let i = 0; i < answers.length; i++) {
    //       answersList.push(answers[i].textContent);
    //     }

    //     localStorage.setItem("answers", JSON.stringify(answersList));

    //     let answersId = xml.querySelectorAll("id");
    //     let answersIdList = [];

    //     for (let i = 0; i < answersId.length; i++) {
    //       answersIdList.push(answersId[i].textContent);
    //     }

    //     localStorage.setItem("id", JSON.stringify(answersIdList));
    //     localStorage.setItem("multi", xml.querySelector("multi").textContent);
    //     localStorage.setItem(
    //       "sessionid",
    //       xml.querySelector("sessionid").textContent
    //     );
    //     localStorage.setItem("tdelta", xml.querySelector("tdelta").textContent);
    //   });

    window.scrollTo(0, 0);
    navigate("/registration");
  }

  return (
    <div>
      <HeaderLK></HeaderLK>
 
      <div className="personalInfo">
        <div className="personalSpan">
          <span>Личные данные</span>
        </div>
        <div className="personalContent">
          {localStorage.getItem("statisticsName") ? (
            <div className="personalConentItem">
              Имя или псевдоним:{" "}
              <div>{localStorage.getItem("statisticsName")}</div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="personalConentItem">
            Возраст: <div>{localStorage.getItem("statisticsAge")}</div>
          </div>
        </div>
      </div>
      <div className="personalSpan">
        <span>Результаты скоринга</span>
      </div>{" "}
      <div className="pieChart">
        {" "}
        <Radar
          data={radarChartData}
          options={{
            legend: {
              display: false,
            },
            scale: {
              ticks: { display: false },
            },
          }}
        />
      </div>
      <div className="socialStatus">
        Социальный статус: {localStorage.getItem("socialStatus")}
      </div>
      <div className="registrationEndBox">
        {loading ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <button className="registrationEnd" onClick={startTest}>
            Попробовать ещё раз
          </button>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}
